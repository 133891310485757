@import './_all_vars.sass'

.focusDot
  width: 10px
  height: 10px
  background-color: red
  border-radius: 50%
  position: absolute
  top: 12px
  left: 14px
  z-index: 1
  animation-name: shrink
  animation-duration: 2s
  animation-iteration-count: infinite
  animation-timing-function: linear

@keyframes shrink
  from
    transform: scale(1)

  50%
    transform: scale(0.5)

  to
    transform: scale(1)