$aurecon-interpreter-main-dark: #263238;
$aurecon-interpreter-main-light: #00796b;
$aurecon-interpreter-pill-in-progress: #4418c2;
$aurecon-interpreter-pill-closed: #c26a18;
$aurecon-interpreter-pill-public: #246b03;
$aurecon-interpreter-card-border: #d4d4d4;
$aurecon-interpreter-settings-header-border: #d4d4d4;
$aurecon-interpreter-dropdown-background: #fafafa;
$aurecon-interpreter-nav-button-color: #b2dfdb;
$aurecon-interpreter-editor-tool-bar-background: #1b4747;

$aurecon-interpreter-status-not-started: #fff;
$aurecon-interpreter-status-in-progress: #f6d510;
$aurecon-interpreter-status-internal-review: #14a4de;
$aurecon-interpreter-status-awaiting-external-review: #843dbc;
$aurecon-interpreter-status-external-review-open: #de8d14;
$aurecon-interpreter-status-external-review-closed: #da3b20;
$aurecon-interpreter-status-approved: #00bd39;
$aurecon-interpreter-status-creating-revision: #00eeff;
$aurecon-interpreter-status-creating-revision-error: #f80a0a;
$aurecon-interpreter-status-cloning-maps: #0077ff;

$aurecon-interpreter-modal-background-opacity: rgba($aurecon-interpreter-main-light, 0.2);
$aurecon-interpreter-lock-own: #00bd39;
$aurecon-interpreter-lock-other: #de8d14;

$aurecon-interpreter-dropdown-option-background: #80cbc4;
$aurecon-interpreter-dropdown-editor-tool-bar-selected: #00695c;
$aurecon-interpreter-map-view-button-hover: #004d40;
$aurecon-interpreter-drap-indicator: #919191;
$aurecon-interpreter-text-light: #666;
$aurecon-interpreter-text-disabled: #888;

$aurecon-interpreter-text-reverse: $aurecon-interpreter-dropdown-background;

$aurecon-interpreter-navigator-menu-background: #fafafa;
$aurecon-interpreter-navigator-menu-open: #f2f2f2;
$aurecon-interpreter-navigator-menu-active-background: rgba(0, 121, 107, 0.1);
$aurecon-interpreter-settings-menu-active-background: rgba(0, 121, 107, 0.1);
$aurecon-interpreter-navigator-menu-active-font: #004d40;

$aurecon-interpreter-structure-page-background: #fafafa;
$aurecon-interpreter-structure-chapter-background: #f2f2f2;

$aurecon-interpreter-map-preview-background-color: #e5e5e5;
$aurecon-interpreter-map-tag: #c2185b;
$aurecon-interpreter-input-character-left: #757575;
$aurecon-interpreter-form-input: #666666;
$aurecon-interpreter-user-search-item-color: #004053;
$aurecon-interpreter-user-search-item-hover: #d9e9e7;

$primary--colour--main: $aurecon-interpreter-main-light;
$primary--colour--main--dark: $aurecon-interpreter-main-dark;
$primary--colour--main--light: $aurecon--cobaltblue--300;

$pill--colour--1: $aurecon-interpreter-pill-in-progress;
$pill--colour--2: $aurecon-interpreter-pill-closed;
$pill--colour--3: $aurecon-interpreter-pill-public;
$pill--colour--4: $aurecon-interpreter-main-light;
$pill--colour--5: $aurecon-interpreter-map-tag;
$pill--colour--16: $aurecon--cobaltblue--050;

$aurecon-interpreter-status-warning: #da3b20;

$aurecon-interpreter-map-placeholder-background: #fafafa;

$aurecon-interpreter-icon-colour-opacity: rgba($aurecon-interpreter-main-light, 0.3);

$aurecon-interpreter-time-slider-max-min-date-color: #515252;
$aurecon-interpreter-time-slider-current-date-color: #6d9cce;

$aurecon-interpreter-create-new-theme-base-theme-background-color: #f2f2f2;
$aurecon-interpreter-create-new-colour-input-wrapper-background-color: #fafafa;

$aurecon-interpreter-manage-feedback-type-background-color: #f2f2f2;
$aurecon-interpreter-manage-feedback-type-border-color: #f2f2f2;
$aurecon-interpreter-manage-feedback-type-border-color-active: $aurecon-interpreter-main-light;
$aurecon-interpreter-manage-feedback-type-border-color-hover: $aurecon-interpreter-main-light;
$aurecon-interpreter-manage-feedback-link-text-color: #121212;

$aurecon-interpreter-external-feedback-field-background-color: #f2f2f2;

$aurecon-interpreter-feedback-button-disabled-color: #919191;

$aurecon-interpreter-search-content-icon-color: #00796B;
$aurecon-interpreter-search-content-input-background-active: #eee;

$aurecon-interpreter-report-setting-logo-container-text-color: #121212;
$aurecon-interpreter-report-setting-logo-container-text-highlight-color: #00796B;
$aurecon-interpreter-report-setting-logo-container-note-add-icon-color: #c4c4c4;

$recall-main-darker: #006358;
$recall-main-dark: #00796b;
$recall-main-light: #068576;
$recall-status-warning: #da3b20;
$recall-status-info: #2064da;
$recall-dropdown-background: #fafafa;
$recall-dropdown-option-background: #b2dfdb;
$recall-banner-message-background: #fcf6a6;

$recall-heading: #373a36;
$recall-text-menu: #373a36;
$recall-text-menu-background-hover: #dee1dd;
$recall-text-light: #666;
$recall-message-text-light: #555;
$recall-text-lighter: #919191;
$recall-text-disabled: #888;
$recal-input-primary-text-colour: #696c67;

$recall-code-background: #eee;
$recall-file-drop-background: #eee;
$recall-file-drop-color: #aaa;

$recall-app-tile-border: #eaeaea;

$recall-app-card-border: #eaeaea;
$recall-menu-background: #eee;
$recall-menu-active: #ddd;
$recall-separator: #cdcdcc;

$recall-primary: #00796b;

$recall-bamboo-button-border: #6f49ad;
$recall-bamboo-button-background: #6f49ad;
$recall-bamboo-button-background-pressed: #4e5859;
$recall-bamboo-button-background-hover: #373a36;
$recall-bamboo-button-background-disabled: #e5e9e4;
$recall-bamboo-button-text-disabled: #91948f;
$recall-bamboo-button-color: #ffffff;
$recall-bamboo-button-secondary-color: #000000;
$recall-bamboo-button-icon-color: #000000;
$recall-button-border-focus: #373a36;

$recall-chat-button-border: #0a192f;
$recall-chat-button-background: #9399a3;
$recall-chat-button-background-hover: #bdbdbd;

$recall-recall-chat: #85c446;
$recall-recall-chat-hover: #43a057;
$recall-recall-chat-color: #0a192f;

$recall-gpt-chat: #f6d510;
$recall-gpt-chat-hover: #aaaa00;
$recall-gpt-chat-color: #0a192f;

$recall-code-chat: #b2e3f4;
$recall-code-chat-disabled: #c4f7ea;
$recall-code-chat-hover: #4387a0;
$recall-code-chat-color: #0a192f;
$recall-code-replaced-text-color: #8e9c9c;

$recall-bamboo-chat: #6f49ad;

$recall-winwise: #45c5b6;
$recall-bamboo: #ffdfaf;
$recall-vine: #e3d2ff;

$recall-bamboo-header-background: #dbdfdf;
$recall-bamboo-header-selected-file: #373a36;
$recall-bamboo-header-selected-filename: #6f49ad;
$recall-bamboo-header-selected-filepage: #373a36;
$recall-bamboo-header-manage-file-button-background: #fff;
$recall-bamboo-header-manage-file-button-color: #373a36;
$recall-bamboo-header-right-side-background: #ededed;
$recall-bamboo-entity-sidebar-background: #fafafa;
$recall-bamboo-entity-sidebar-title: #373a36;
$recall-bamboo-property-no-data-text-color: #8e9c9c;
$recall-bamboo-file-manager-description-text: #8e9c9c;
$recall-bamboo-home-text-color: #8e9c9c;
$recall-bamboo-form-input-border-color: #373a36;
$recall-bamboo-form-input-focus-color: #6f49ad;
$recall-bamboo-modal-placeholder-color: #8e9c9c;

$recall-bamboo-dropdown-background: #fff;
$recall-bamboo-dropdown-selected-background: #4e5859;
$recall-bamboo-dropdown-selected-text: #ffffff;
$recall-bamboo-dropdown-selected-background-hover: #e3d2ff;
$recall-bamboo-dropdown-selected-border-hover: #6f49ad;
$recall-bamboo-dropdown-selected-background-border: #4e5859;
$recall-bamboo-dropdown-text: #8e9c9c;
$recall-bamboo-dropdown-options-text-color: #000000;
$recall-bamboo-dropdown-text-hover-color: #ffffff;
$recall-bamboo-dropdown-icon: #6f49ad;

$recall-bamboo-checkbox-checked-background: #373a36;
$recall-bamboo-checkbox-hover-background: #6f49ad;
$recall-bamboo-checkbox-icon-color: #ffffff;
$recall-bamboo-close-modal-icon-color: #373a36;
$recall-bamboo-entity-modal-description-color: #8e9c9c;
$recall-bamboo-entity-modal-cancel-background: #fff;
$recall-bamboo-entity-description-color: #8e9c9c;
$recall-bamboo-entity-selected-background: #ffffff;
$recall-bamboo-property-modal-title-color: #171a16;
$recall-bamboo-table-cell-border-bottom: #f2f2ed;

$recall-bamboo-overflowmenu-icon-background: #6f49ad;
$recall-bamboo-overflowmenu-menu-options: #ffffff;
$recall-bamboo-overflowmenu-menu-options-hover: #fafafa;

//Bamboo darkmode
$recall-bamboo-header-background-darkmode: #4f534d;
$recall-bamboo-header-manage-file-button-background-darkmode: #1b1b1b;
$recall-bamboo-header-manage-file-button-color-darkmode: #aeb1b7;
$recall-bamboo-header-right-side-background-darkmode: #444742;
$recall-bamboo-file-manager-description-text-darkmode: #bdcece;

$recall-bamboo-button-background-darkmode: #c7b3e9;
$recall-bamboo-button-border-darkmode: #c7b3e9;
$recall-bamboo-button-background-pressed-darkmode: #ffffff;
$recall-bamboo-button-background-hover-darkmode: #efefef;
$recall-bamboo-dropdown-selected-border-hover-darkmode: #e3d2ff;
$recall-bamboo-button-background-disabled-darkmode: #4d4d4d;
$recall-bamboo-button-text-disabled-darkmode: #91948f;
$recall-bamboo-button-color-darkmode: #000000;
$recall-bamboo-button-secondary-color-darkmode: #000000;
$recall-bamboo-button-icon-color-darkmode: #ffffff;
$recall-button-border-focus-darkmode: #efefef;

$recall-bamboo-entity-sidebar-background-darkmode: #333333;
$recall-bamboo-header-selected-file-darkmode: #aeb1b7;
$recall-bamboo-header-selected-filename-darkmode: #ffffff;
$recall-bamboo-header-selected-filepage-darkmode: #ffffff;
$recall-bamboo-entity-sidebar-title-darkmode: #ffffff;
$recall-bamboo-property-no-data-text-color-darkmode: #8e9c9c;
$recall-bamboo-form-input-border-color-darkmode: #ffffff;
$recall-bamboo-form-input-focus-color-darkmode: #c7b3e9;
$recall-bamboo-modal-placeholder-color-darkmode: #bdcece;
$recall-bamboo-dropdown-background-darkmode: #e3d2ff;
$recall-bamboo-dropdown-text-darkmode: #bdcece;
$recall-bamboo-dropdown-selected-text-darkmode: #1b1b1b;
$recall-bamboo-dropdown-selected-background-darkmode: #4e5859;
$recall-bamboo-dropdown-selected-background-hover-darkmode: #e3d2ff;
$recall-bamboo-dropdown-selected-background-border-darkmode: #ffffff;
$recall-bamboo-dropdown-options-text-color-darkmode: #000000;
$recall-bamboo-dropdown-text-hover-color-darkmode: #ffffff;
$recall-bamboo-dropdown-icon-darkmode: #e3d2ff;
$recall-bamboo-checkbox-checked-background-darkmode: #ffffff;
$recall-bamboo-checkbox-checked-background-hover-darkmode: #c7b3e9;
$recall-bamboo-checkbox-hover-background-darkmode: #6f49ad;
$recall-bamboo-checkbox-icon-color-darkmode: #373a36;
$recall-bamboo-close-modal-icon-color-darkmode: #373a36;
$recall-bamboo-entity-modal-description-darkmode: #bdcece;
$recall-bamboo-entity-modal-cancel-background-darkmode: transparent;
$recall-bamboo-entity-description-color-darkmode: #bdcece;
$recall-bamboo-home-text-color-darkmode: #bdcece;
$recall-bamboo-chat-darkmode: #c7b3e9;
$recall-bamboo-entity-selected-background-darkmode: #1b1b1b;
$recall-bamboo-table-cell-border-bottom-darkmode: #373a36;
$recall-bamboo-file-upload-text-color-darkmode: #aeb1b7;

$recall-bamboo-overflowmenu-icon-background-darkmode: #c7b3e9;
$recall-bamboo-overflowmenu-menu-options-darkmode: #373a36;
$recall-bamboo-overflowmenu-menu-options-hover-darkmode: #4e5859;

$recall-editor-tool-bar-background: #1b4747;
$recall-pagination-background: #263238;

$primary--text--colour: #000;
$primary--text--colour--light: $aurecon--grey--800;
$primary--text--colour--lighter: $aurecon--grey--700;
$secondary--text--colour: #fff;
$secondary--text--colour--light: $aurecon--grey--200;
$secondary--text--colour--dark: $aurecon--grey--400;

$recall-pill-info-text: #263238;

$pill--colour--1: #eee;
$pill--colour--2: $recall-recall-chat;
$pill--colour--3: $recall-gpt-chat;
$pill--colour--4: #87e0d5;

//Darkmode Pill Pallete
$pill--colour--16: #373a36;

$recall-menu-width: 275px;
$recall-menu-width-small: 48px;
$recall-menu-logo-width: 175px;
$recall-menu-logo-width-small: 48px;

$recall-content-width-desktop: 650px;
$recall-content-width-desktop-large: 880px;

$breakpoint--mobile: 576px;
$breakpoint--tablet: 768px;
$breakpoint--desktop: 992px;
$breakpoint--desktop-large: 1200px;

$recall--modal--background: rgba(0, 0, 0, 0.9);

$recall-primary-white: #fff;
$recall-primary-black: #000;

// DARKMODE COLOURS
$recall-menu-background-hover-darkmode: #373a36;
$recall-active-background-colour: #4e5859;
$recall-menu-background-darkmode: #373a36;
$recall-primary-black-darkmode: #1b1b1b;
$recall-pagination-items-background-darkmode: #8e9c9c;
$recall-dropdown-background-darkmode: #373a36;
$recall-code-replaced-text-color-darkmode: #bdcece;

$recall-primary-text-colour-darkmode: #aeb1b7;
$recall-heading-darkmode: #aeb1b7;
$recall-question-box-darkmode: #2d302c;
$recall-separator-darkmode: #545556;

$recall-green-200-darkmode: #b8df7c;
$recall-green-300-darkmode: #85c446;
$recall-green-500-darkmode: #89c925;
$recall-green-600-darkmode: #00bd39;
$recall-green-700-darkmode: #008a15;

$recall-file-preview-background: #dbdfdf;
$recall-file-preview-background-darkmode: #4f534d;