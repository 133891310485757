@mixin lato-thin {
    font-family: "Lato", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  @mixin lato-light {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  @mixin lato-regular {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  @mixin lato-bold {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  @mixin lato-black {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  @mixin lato-thin-italic {
    font-family: "Lato", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  @mixin lato-light-italic {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  @mixin lato-regular-italic {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  @mixin lato-bold-italic {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  @mixin lato-black-italic {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: italic;
  }