@import './_all_vars.sass'

.tooltipWrapper

  span:global(.tooltip-popup)
    top: -40px !important
    @include lato-light

  .fileAttachButtonWrapper
   

        &:focus:enabled:not(.is-loading)
          background-color: mix($recall-chat-button-background, white) !important

        .chatGpt
            background-color: $recall-gpt-chat
            border-color: $recall-gpt-chat

        .filterCounter
            position: absolute
            top: 0px
            right: -5px
            height: 15px
            width: 15px
            text-align: center
            border-radius: 50%
            font-size: 11px