@import './_all_vars.sass'

.input
  flex: 1

  label
    div
      > textarea
        background-color: transparent !important
        border: none !important
        padding: 8px !important
        height: 40px
        resize: none

.input:global(.field-wrapper)
  label
    line-height: 11px

    textarea
      font-family: "Lato", sans-serif !important

.submitButton:global(.is-primary)
  border-radius: 20px

  &.chatGpt
    background-color: $recall-gpt-chat
    border-color: $recall-gpt-chat
    color: $recall-gpt-chat-color

    &:hover:enabled:not(.is-loading)
      background-color: mix($recall-gpt-chat, $recall-gpt-chat-hover) !important
      border-color: $recall-gpt-chat
      color: $recall-gpt-chat-color

    &:focus:enabled:not(.is-loading)
      border-color: $recall-gpt-chat-hover
      background-color: $recall-gpt-chat-hover !important

  &.chatRecall
    background-color: $recall-recall-chat
    border-color: $recall-recall-chat
    color: $recall-recall-chat-color

    &:hover:enabled:not(.is-loading)
      background-color: mix($recall-recall-chat, $recall-recall-chat-hover) !important
      border-color: $recall-recall-chat
      color: $recall-recall-chat-color

    &:focus:enabled:not(.is-loading)
      border-color: $recall-recall-chat-hover
      background-color: $recall-recall-chat-hover !important

@media screen and (max-width: $breakpoint--tablet + 1)
  .input
    label
      div
        > textarea
          padding: 8px 40px 8px 12px