@import './_all_vars.sass'

.page
  display: flex
  flex-direction: column
  width: 100%
  height: 100vh
  overflow: hidden
  align-items: center // Add this line to center horizontally
  justify-content: center // Add this line to center vertically

    
  .pageContents
    display: flex
    justify-content: center
    flex: 1
    overflow: auto

  .layoutContainer
    display: flex
    flex: 1
    flex-direction: column

  .mainPageWrapper
    flex: 1
    display: flex
    overflow: auto
